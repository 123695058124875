<script></script>

<template>
  <div class="flex justify-center items-center text-center">
    <div
      class="font-general-regular text-lg text-ternary-dark dark:text-ternary-light">
      © 2025 Arif Islam. All rights reserved.
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
