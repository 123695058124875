import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

import NProgress from "nprogress";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: {
      title: "About",
    },
  },
  {
    path: "/projects",
    name: "Projects",
    component: () => import("../views/Projects.vue"),
    meta: {
      title: "Projects",
    },
  },

  {
    path: "/project/:permalink",
    name: "SingleProject",
    component: () => import("../views/SingleProject.vue"),
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: () => import("../views/Blogs.vue"),
    meta: {
      title: "Blogs",
    },
  },
  {
    path: "/blog/:permalink",
    name: "SingleBlog",
    component: () => import("../views/SingleBlog.vue"),
  },

  {
    path: "/uses",
    name: "Uses",
    component: () => import("../views/Uses.vue"),
    meta: {
      title: "Uses",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
    meta: {
      title: "Contact",
    },
  },

  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
    meta: {
      title: "404",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    // NProgress.start();
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.meta.title) {
    document.title = to.meta.title + " - Arif Islam";
  } else if (to.name === "SingleProject") {
    const title = to.params.permalink
      .replace(/-/g, " ")
      .replace(/(\w)(\w*)/g, function (_, first, rest) {
        return first.toUpperCase() + rest.toLowerCase();
      });
    document.title = title + " - Arif Islam";
  } else if (to.name === "SingleBlog") {
    const title = to.params.permalink
      .replace(/-/g, " ")
      .replace(/(\w)(\w*)/g, function (_, first, rest) {
        return first.toUpperCase() + rest.toLowerCase();
      });
    document.title = title + " - Arif Islam";
  } else {
    document.title = "Arif Islam";
  }
  next();
});

export default router;
