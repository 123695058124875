const blogs_items = [
  {
    id: 9,
    title: "Subject Review : Computer Science & Engineering (CSE)",
    category: "Software Engineering",
    date: "2023-11-12",
    color: "#e8af59",
    img: require("@/assets/images/blogs/subject-review-cse.webp"),
    short_desc:
      "সিএসই পড়তে গেলে যেই জিনিসটা খুবই দরকারী সেটা হলো ক্রিয়েটিভিটি, প্যাশন, লিডারশীপ কোয়ালিটি এবং অফকোর্স মাথায় রাখতে হবে সবসময় সিএসই অন্য যে  ",
    desc: `
    <p>সিএসই পড়তে গেলে যেই জিনিসটা খুবই দরকারী সেটা হলো ক্রিয়েটিভিটি, প্যাশন, লিডারশীপ কোয়ালিটি এবং অফকোর্স মাথায় রাখতে হবে সবসময় সিএসই অন্য যে কোন সাবজেক্টের চাইতে আলাদা। এটার সাথে অনেকটা চারুকলা, আর্কিটেকচারের মিল আছে। যে কোন সাবজেক্টের ইন্ডাস্ট্রীতে চাকরী পেতে হলে লাগে ভালো রেজাল্ট, মামা চাচার জোড়, রুপচাদা খাটি সয়াবিন তেল এবং টিকে থাকতে গেলে চালিয়ে যেতে হবে পলিটিক্স এবং তেল। এগুলাই উন্নতির চাবিকাঠি।</p>
    <p>কিন্তু সিএসইতে তোমার রেজাল্ট যদি ২ পয়েন্ট সামথিং থাকে (আউট অফ ৪), একগাদা ড্রপ কোর্স থাকে, হাতে সার্টিফিকেটও না থাকে তবুও তুমি ইন্ডাস্ট্রী কাপিয়ে দিতে পারবে যদি তোমার ক্রিয়েটিভিটি, প্যাশন, লিডারশীপ থাকে। কারণ এই তিনটা জিনিসের কম্বিনেশনে তুমি একটা জিনিসে বস হয়ে যেতে পারবে যেটাকে বলা হয় প্রোগ্রামীং।</p>
    <p>এখন একটু ভেঙে বলি এ তিনটা জিনিস কেন দরকার।</p>
    <ul>
        <li>ক্রিয়েটিভিটি : তোমার কি রাতের বেলায় আইডিয়ার ঠেলায় ঘুম আসেনা? তাহলে তুমি সফটওয়ার ডেভলপমেন্টে ভাল করবে। কারণ ইন্ডাস্ট্রী ক্রিয়েটিভিটি চায়। দিন যাচ্ছে আর মানুষের কাছে চাহিদা বাড়ছে নতুন নতুন টেকনোলজির। সুতরাং নতুন কিছু বানানোর মত ক্ষমতা তোমার থাকতে হবে। তোমার মাথায় সবসময় নতুন কিছু ঘুরপাক খেতে হবে। নাহলে একাডেমিক প্রজেক্ট করার সময় তোমাকে দিশেহারা হয়ে ঘুরতে হবে। ফাইনাল ইয়ারে থিসিসের টপিক খুজে পাবেনা যেটা খুবই দু:খজনক ব্যাপার ৩ বছর পড়াশোনা করার পর।</li>
        <li>প্যাশন : নতুন কিছু বানানোর ক্ষমতা থাকলেই হবেনা সেই সাথে ইচ্ছাটাও থাকতে হবে। ক্রিয়েটিভ লোকজন সবচাইতে বেশী হতাশায় ভোগে। সেই হতাশা তোমাকে কাটিয়ে উঠতে হবে। সব বাধা ভেঙে দিয়ে কিছু করতে হলে প্যাশন থাকা জরুরী এবং সেই প্যাশনটা অবশ্যই সিএসইর জন্যই হতে হবে। সিএসইতে পড়ে পলিটিক্স নিয়ে প্যাশন থাকলে সিএসইর কোন আউটকাম আসবেনা এটা কনফার্ম।</li>
        <li>লিডারশীপ : A Leader is a by born Problem Solver. সিএসই পড়ার মানেই হলো নতুন নতুন প্রবলেম ম্যাথমেটিকসের লজিক এপ্লাই করে, ফিজিক্সের রুল দিয়ে সলভ করা। যে রিয়েল লাইফে ভাল প্রবলেম সলভার সে খুব ভালভাবে জানে একটা প্রবলেম কিভাবে সলভ করতে হয়। তাছাড়া একটা যে কোন লেন্থি কাজ আজকে কিভাবে শুরু করলে দশ
         দিন পরে এটার ফল কি হবে এটা লিডার ছাড়া কেউ বলতে পারবেনা। লিডারশীপ যার মধ্যে থাকে তার মধ্যে ক্রিয়েটিভিটিও অটোমেটিক চলে আসে।</li>
    </ul>
    <p>স্বপ্ন দেখতে হবে এবং স্বপ্ন সত্যি করার জন্য এনাফ গাটস থাকতে হবে। সাবজেক্টটার প্রতি ভালবাসা না থাকলে ভাল করা সম্ভব না।</p>
    <p>একটা ছোট্ট উদাহরণ দেই একজন বড়ভাইয়ের। উনি শাবির ৯৭/৯৮ ব্যাচের মনির ভাই। পাগল টাইপের মানুষ। ক্লাশ করতেননা। দিনরাত নতুন নতুন সফটওয়ার বানাতেন। অনেক ড্রপ কোর্স ছিলো। রেজাল্ট ৩ এর নীচে। উনার ব্যাচের ৪/২ পরীক্ষার পরে উনার অনেক কোর্স বাকী ছিলো। সার্টিফিকেট ছাড়াই উনি চাকরী পেয়ে গেলেন মাইক্রোসফট এ। জাফর স্যার উনাকে আমেরিকা থেকে আনিয়ে কোর্সগুলা কমপ্লিট করিয়েছিলেন। কথায় কথায় স্যার একবার মনির ভাইকে জিজ্ঞেস করেছিলেন যে তোমার রেজাল্ট, সার্টিফিকেট নিয়ে এরা কিছু বলেনা? মনির ভাই উত্তর দিলেন, "না। ওরা ভয় পায় আমি যদি তাহলে চাকরী ছেড়ে দেই।"</p>
    
    <p>
    লিখেছেন সুদীপ্ত কর - CSE 4/2, SUST</p>
    `,
  },

  {
    id: 10,
    title: "'Hello World' Program in Different Programming Languages",
    category: "Programming",
    date: "2024-11-23",
    color: "#367371",
    img: require('@/assets/images/blogs/hello-world.webp'),
    short_desc:
      "প্রোগ্রামিং শিখার প্রথম ধাপ হিসাবে 'Hello World' প্রোগ্রাম লেখা অত্যন্ত গুরুত্বপূর্ণ। এই সহজ প্রোগ্রামটি দিয়ে শুরু করে নতুন প্রোগ্রামাররা প্রোগ্রামিং ভাষার মৌলিক ধারণা পেতে পারেন।",
    desc: `
  <p>যেকোনো প্রোগ্রামিং ভাষায় 'Hello World' প্রোগ্রাম লেখা শুরুর প্রথম ধাপ। এই সহজ প্রোগ্রামটি প্রোগ্রামিং ভাষার মৌলিক সিনট্যাক্স ও কাঠামো শেখার একটি পথ প্রদর্শক। নিচে আমরা কিছু জনপ্রিয় প্রোগ্রামিং ভাষায় 'Hello World' প্রোগ্রাম লেখার উদাহরণ দেখব।</p>
  <h3>Python</h3>
  <pre><code class="language-python">print("Hello, World!")</code></pre>
  <h3>JavaScript</h3>
  <pre><code class="language-javascript">console.log("Hello, World!");</code></pre>
  <h3>Java</h3>
  <pre><code class="language-java">public class HelloWorld {
  public static void main(String[] args) {
      System.out.println("Hello, World!");
  }
}</code></pre>
  <h3>C</h3>
  <pre><code class="language-c">#include &lt;stdio.h&gt;
int main() {
  printf("Hello, World!\\n");
  return 0;
}</code></pre>
  <h3>C++</h3>
  <pre><code class="language-cpp">#include &lt;iostream&gt;
using namespace std;
int main() {
  cout &lt;&lt; "Hello, World!" &lt;&lt; endl;
  return 0;
}</code></pre>
  <h3>PHP</h3>
  <pre><code class="language-php">echo "Hello, World!";</code></pre>
  <p class='mt-5'>এই ভাষাগুলিতে 'Hello World' প্রোগ্রাম লিখে নতুন প্রোগ্রামাররা প্রোগ্রামিং ভাষার বিভিন্ন পদ্ধতি ও প্রয়োগ সম্পর্কে আরো ভালো ধারণা পেতে পারবেন।</p>
`,
  },
];

const blogs = blogs_items.map((blog) => {
  const title = blog.title
    .replace(/[^a-zA-Z0-9\s-]/g, "") // Remove unwanted characters
    .replace(/ /g, "-") // Replace spaces with hyphens
    .toLowerCase();
  const permalink = title;
  return {
    ...blog,
    permalink,
  };
});

export default blogs;
